.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
